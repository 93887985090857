<template>
  <section v-editable="blok" hero-video :style="[bgStyle]">
    <div class="container container--bleed">
      <div class="section-row">
        <div class="section-content">
          <h1 class="section-title" v-if="blok.title" v-html="blok.title"></h1>
          <div class="section-description" v-if="blok.description" v-html="blok.description"></div>
          <div v-if="blok.buttons" class="section-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
        <div class="section-image" v-if="blok?.video?.length > 0">
          <video loop muted playsInline class="w-full h-auto" autoplay :poster="optimizeImage(blok.image.filename)">
            <source v-for="(source, index) in videoSources" :key="index" :src="source.url" :type="source.type" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="section-image" v-else>
          <img v-if="blok.image?.filename" :src="optimizeImage(blok.image.filename)" alt="Hero Banner Image" />
        </div>
      </div>
      <div class="section-partners" v-if="blok.partners">
        <div class="section-partners-label">Trusted by over 35,000 businesses globally</div>
        <div class="section-partners-row">
          <NuxtImg provider="storyblok" :src="src.filename" v-for="(src, s) in blok.partners" :key="s" :alt="`Partner ${src.filename.replace('/img/', '').replace('-logo.svg', '')}`" v-bind="dimensions(src)" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/hero-video.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  useHead({
    link: [
      {
        rel: "preload",
        as: "image",
        fetchpriority: "high",
        href: optimizeImage(props.blok.background?.filename),
      },
      {
        rel: "preload",
        as: "video",
        href: props.blok?.video,
        type: "video/webm",
      },
    ],
  });

  const bgStyle = computed(() => {
    if (!props.blok.background?.filename) return {};
    return {
      backgroundImage: `url(${props.blok.background?.filename})`,
      backgroundColor: props.blok?.background_color?.color || "#FFF",
    };
  });

  const dimensions = (image) => {
    if (image) {
      const urls = image?.filename?.split("/")[5].split("x");
      return {
        width: urls[0],
        height: urls[1],
      };
    }
    return { width: 0, height: 0 };
  };

  const getVideoSources = (videoString) => {
    if (!videoString) return [];
    return videoString.split(",").map((url) => {
      const extension = url.split(".").pop();
      let type = "";
      switch (extension) {
        case "webm":
          type = "video/webm";
          break;
        case "mov":
          type = "video/quicktime";
          break;
        default:
          type = "";
      }
      return { url, type };
    });
  };

  const videoSources = computed(() => getVideoSources(props.blok?.video));
</script>
